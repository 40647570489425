import React from 'react'
import classNames from 'classnames'

import { requestForAutoOpenCoffeeFaster } from '../../apiRequests/callToAction'
import DefaultLayout from '../../layout/default'
import SimpleFeatures from '../../components/_V2/SimpleFeatures'
import AutomationMenu from '../../components/_V2/AutomationMenu'
import Presentation from '../../assets/images/coffeehouse/coffee__presentation.png'

import CoffeeCtaForm from '../../pagesSections/avtomatizaciya-kofejni/CoffeeCtaForm'
import CtaForm from '../../pagesSections/index/CtaForm'
import ProductPresentation from '../../pagesSections/avtomatizaciya-kofejni/ProductPresentation'

import featuresData from '../../pages-data/_V2/automatization/coffee/features'
import { businessData } from '../../pages-data/_V2/common/business-types-compact-white'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/automation.module.scss'
import BusinessTypes from '../../components/_V2/BusinessTypes'
import { useLocalizationContext } from "../../localization/useLocalizationContext";
import AccountingFeatures from "../../pagesSections/intergations/AccountingFeatures"
import ProductsFeatures from "../../pagesSections/intergations/ProductsFeatures"
import AdditionalServices from "../../pagesSections/index/AdditionalServices"
import SeoText from "../../components/_V2/SeoText";
import { getSeoText } from "../../pages-data/_V2/automatization/coffee/seotext";

export default function PageCoffeehouse() {
  const localizationContext = useLocalizationContext();

  const title = 'Автоматизация кофейни: CRM и программы для управления и система учета | Quick Resto'
  const metaTags = [
    // {
    //   name: 'keywords',
    //   content: 'quick resto, автоматизация кофейни, ресторанный бизнес, программа учёта, склад, общепит',
    // },
    {
      name: 'description',
      content: 'CRM и программы для автоматизации работы кофейни. Улучшите управление и учет в кофейнях с Quick Resto. Простое и эффективное решение с единой системой обслуживания!',
    },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/automation/avtomatizaciya-kofejni/',
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: 'CRM и программы для автоматизации работы кофейни. Улучшите управление и учет в кофейнях с Quick Resto. Простое и эффективное решение с единой системой обслуживания!',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: `https://quickresto.ru${Presentation}`,
    },
  ]

  return (
    <DefaultLayout
      title={title}
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  pageStyles.automationPage,
      )}
      linkCanonical="https://quickresto.ru/automation/avtomatizaciya-kofejni/"
      footerClassName={styles.automation__footer}
      schemaType="Product"
      schemaName={metaTags.find((tag) => tag.property === 'og:title').content}
      schemaDescription={metaTags.find((tag) => tag.property === 'og:description').content}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
      schemaUrl={metaTags.find((tag) => tag.property === 'og:url').content}
    >
      <div
        className={classNames(
					  pageStyles.pageSection,
					  styles.automation__headline,
        )}
      >
        <ProductPresentation
          leftColumn={styles.automation__presentationLeftColumn}
          title={styles.automation__presentationTitle}
        />

        <AutomationMenu className={styles.automation__menu} />
      </div>

      <SimpleFeatures
        data={featuresData.filter((feature, index) => index < 4)}
        className={pageStyles.pageSection}
      />

      <BusinessTypes
        key={localizationContext.locale}
        data={businessData(localizationContext.locale)}
        className={pageStyles.pageSection}
      />

      <CoffeeCtaForm
        className={pageStyles.pageSection}
        request={requestForAutoOpenCoffeeFaster}
      />

      <SimpleFeatures
        data={featuresData.filter((feature, index) => index >= 4)}
        className={pageStyles.pageSection}
      />

      <AccountingFeatures className={pageStyles.pageSection} theme="white" />

      <ProductsFeatures className={pageStyles.pageSection} theme="white" />

      <AdditionalServices className={styles.additionalServices} theme="white" />

      <CtaForm
        isFry={false}
        isWhiteBackground
        className={pageStyles.pageSection}
      />
      <SeoText
          className={pageStyles.pageSection}
          data={getSeoText()}
      />
    </DefaultLayout>
  )
}
