import { Data } from "../../../components/_V2/BusinessTypes/types"
import { getBusinessTypes } from "./business-types"
import { Locales } from "../../../localization/types";

export const businessData = (locale: Locales): Data => {
	return {
		...getBusinessTypes(locale),
		type: "compact",
		theme: "white",
	}
}
