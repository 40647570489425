import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"

import { pagesLinks } from "../../common/links"

export default [
	{
		title: "Больше продаж с модификаторами",
		mobileTitle: (
			<span className={styles.extendedMobileTitle}>
				Больше
				<br />
				продаж с модификаторами
			</span>
		),
		description:
			"<p>Увеличивай средний чек с помощью модификаторов —&nbsp;дополнительных ингредиентов, которые можно предложить к основной позиции. Модификаторами к&nbsp;кофе могут быть сиропы, молоко, топпинги.</p>" +
			"<p>Кассир или бариста не забудет про модификаторы —&nbsp;подсказки отображаются на кассовом терминале автоматически при оформлении заказа.</p>",

		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/coffee-feature-1.png"
				alt="система автоматизации кофейни"
				breakpoints={[440, 680, 810, 568]}
				sizes="(max-width: 500px) 440px, (max-width: 800px) 680px, (max-width: 1319px) 810px, (min-width: 1320px) 568px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature1,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.terminal.href,
	},
	{
		title: "Голосовой набор для скорости работы кофейни",
		description: (
			<div className={styles.smallDescription}>
				<p>
					В часы пик или когда не хватает кассиров за&nbsp;стойкой,
					автоматизация кофейни поможет увеличить скорость обслуживания гостей
					—&nbsp;используй голосовой набор на кассе. Терминал «услышит» заказ
					гостя и найдёт позиции в меню —&nbsp;кассиру нужно будет только
					подтвердить выбор.
				</p>
				<p data-mt="small">
					Опция работает даже в заведениях с фоновой музыкой.
				</p>
			</div>
		),
		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/coffee-feature-2.png"
				alt="касса для кофейни"
				breakpoints={[400, 680, 810, 586]}
				sizes="(max-width: 500px) 400px, (max-width: 800px) 680px, (max-width: 1319px) 810px, (min-width: 1320px) 586px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature2,
		theme: "white",
	},
	{
		title: "Приглашай гостей на кофе через приложение для кофейни",
		description: (
			<div className={styles.appDescription}>
				<p>
					Мы бесплатно разработаем <Link to={pagesLinks.indexWL.href}>мобильное приложение</Link>{" "}
					для&nbsp;кофейни. Предложи гостям предзаказ — они сделают заказ
					заранее и забегут за кофе, даже если очень торопятся.
				</p>
				<p data-mt="small">
					Мобильное приложение интегрировано с CRM для кофейни.
					Все данные гостей сохраняются в базе, и заведение сможет возвращать гостей снова и снова,
					превращать их в лояльных.
				</p>
				<ul>
					<li>
						Отправляй гостям push-уведомления — сообщай о новых акциях, скидках, начисленных бонусах.
					</li>
					<li>
						Предлагай оформить предзаказ, чтобы прилечь тех, кто не любит ждать.
					</li>
					<li>
						Добавляй гостей в программу лояльности. Начисляй баллы за покупки, давай приветственный бонус или настрой подарок ко дню рождения в CRM-системе кофейни.
					</li>
				</ul>
			</div>
		),

		img: (
			<StaticImage
				className={styles.image3}
				src="./assets/coffee-feature-3.png"
				alt="мобильное приложение для кофейни"
				breakpoints={[400, 480]}
				sizes="(max-width: 500px) 400,  (min-width: 501px) 480"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		reverse: true,

		className: styles.feature3,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.indexWL.href,
	},
	{
		title: "Знай своего гостя в лицо",
		description: (
			<div className={styles.appDescription}>
				<p>
					Собирай информацию о предпочтениях гостей в единой базе: когда они приходят, какие блюда выбирают, какой сироп
					добавляют к десерту.
					Скидка 5% или промокод на доставку? Узнай, что популярнее, в этом месяце.
				</p>
					<p data-mt="small">
						В CRM-системе для кофеен предусмотрены все инструменты, чтобы гость вернулся и рекомендовал заведение
						друзьям.
						Бонусы, персональные скидки, промокоды.
						Дели посетителей на группы по среднему чеку или частоте заказов и делай специальные предложения.
					</p>
			</div>
),

img: (
			<StaticImage
				className={styles.image5}
				src="./assets/coffee-feature-8.png"
				alt="работа с клиентами в бэк-офисе"
				breakpoints={[400, 480]}
				sizes="(max-width: 500px) 400,  (min-width: 501px) 480"
				objectFit="contain"
				placeholder="blurred"
				quality={100}
			/>
		),
		reverse: true,

		className: styles.feature3,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.marketing.href,
	},
	{
		title: "Дисплей покупателя кофейни",
		description: (
			<div className={styles.smallDescription}>
				<p>
					Гости расплачиваются у стойки? Установи дисплей покупателя: гость
					будет видеть свой заказ с&nbsp;фотографиями блюд и бонусный&nbsp;счёт.
				</p>
				<p data-mt="small">
					CRM для кофеен, объединённая с программой учёта, защитит от махинаций. Нечестный сотрудник не сможет создать себе
					бонусный счёт, начислять и обналичивать баллы. Гости авторизуются в системе только
					через идентификатор в мобильном приложении заведения. С дисплеем покупателя это будет
					удобно для всех посетителей и безопасно для кофейни.
				</p>
			</div>
		),

		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/coffee-feature-4.png"
				alt="дисплей покупателя для кофейни"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature4,
		theme: "white",
	},
	{
		title: "Права доступа — защита от махинаций",
		description:(
			<div className={styles.smallDescription}>
			<p>
				Автоматизация кофейни поможет ограничить доступ персонала к потенциально
				опасным возможностям: кассиры не смогут возвращать чеки и отменять пречеки, применять
				фиксированные скидки без авторизации через приложение, смотреть отчёты по продажам,
				удалять позиции заказа и другое.
			</p>
			<p data-mt="small">
				Чтобы исключить махинации с программой лояльности и ошибки кассиров, можно ограничить доступ к CRM кофеен.
				Запрети сотрудникам добавлять новых клиентов в базу или редактировать их данные.
			</p>
			</div>
		),

		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/coffee-feature-5.png"
				alt="автоматизация кофеен"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature5,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.terminal.href,
	},
	{
		title: "Простая инвентаризация кофейни на терминале",
		description: (
			<p className={styles.smallDescription}>
				Делай инвентаризацию прямо на кассовом терминале — не нужно
				распечатывать отдельный акт, просто используй планшет. Автоматизация
				кофейни — простое решение, чтобы держать склад под контролем без
				головной боли.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image6}
				src="./assets/coffee-feature-6.png"
				alt="инвентаризация в кофейне"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature6,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.terminal.href,
	},
]
