import React, { useState } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import PhoneBackdropBubble from "./assets/PhoneBackdropBubble"

import ConsultationCtaFormA from "../ConsultationCtaFormA"
import ConsultationCtaFormB from "../ConsultationCtaFormB"
import AdaptiveWrapText from "../AdaptiveWrapText"

interface Props {
	className?: string
	data: {
		icon?: JSX.Element
		title: string | JSX.Element
		mobileTitle?: string
		description: string | JSX.Element
		phone: {
			value: string
			text: string
		}
		ctaButtonText: string
	}
	withIcon?: boolean

	request?: Function
}

export default function AutomationCtaA(props: Props) {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	const type = "b"

	return (
		<>
			<section
				className={classNames(styles.section, props.className, {
					[styles.sectionWithIcon]: props.withIcon,
				})}
			>
				<div className={styles.content}>
					{props.data.icon && (
						<div className={styles.icon}>{props.data.icon}</div>
					)}
					<div className={styles.contentLeft}>
						{/* <h2 className={styles.title}>
                            <AdaptiveWrapText
                                desktop={props.data.title}
                                mobile={props.data.mobileTitle}
                            />
                        </h2> */}

						<AdaptiveWrapText
							desktop={props.data.title}
							mobile={props.data.mobileTitle}
							titleClasses={styles.title}
						>
							<h2>{props.data.title}</h2>
						</AdaptiveWrapText>
						<p className={styles.description}>{props.data.description}</p>
					</div>
					<div className={styles.contentRight}>
						<a
							className={styles.contactPhoneNumberButton}
							href={`tel:${props.data.phone.value}`}
						>
							<PhoneBackdropBubble className={styles.phoneBackdrop} />
							<span className={styles.phoneNumberButtonText}>
								{props.data.phone.text}
							</span>
						</a>
						<a
							className={styles.ctaButton}
							onClick={handleModalStatus}
							href={"#popup"}
						>
							{props.data.ctaButtonText}
						</a>
					</div>
				</div>
			</section>

			<ConsultationCtaFormA
				isOpen={isModalOpen}
				onClose={handleModalStatus}
				request={props.request}
			/>

			{/*{ type === 'a' ? (*/}
			{/*    <ConsultationCtaFormA*/}
			{/*        isOpen={isModalOpen}*/}
			{/*        onClose={handleModalStatus}*/}
			{/*    />*/}
			{/*) : (*/}
			{/*    <ConsultationCtaFormB*/}
			{/*        isOpen={isModalOpen}*/}
			{/*        onClose={handleModalStatus}*/}
			{/*    />*/}
			{/*)}*/}
		</>
	)
}
